import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import CommonButton from "../Share/CommonButton/CommonButton";
import Link from "../Share/Link/Link.js";
import Disabled from "../Share/Disabled/Disabled.js";

import Route from "../Share/Route/Route.js";

const MainNavbar = () => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark_blue_bg"
        fixed="top"
        variant="dark"
        className="border-bottom border-1 red_border_color dark_blue_bg"
      >
        <Container>
          <Route to="/">
            <img
              src="/images/brand.svg"
              className="img-fluid navlog"
              alt="Nav bar logo"
              style={{ height: "40px" }}
            />
          </Route>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link className="text-white px-3" href="/">
                HOME
              </Nav.Link>

              <Nav.Link>
                <Route to="/speakers" text="SPEAKERS" />
              </Nav.Link>
              <Nav.Link>
                <Route to="/agenda" text="AGENDA" />
              </Nav.Link>
            </Nav>

            <Nav>
              <Disabled text="Sold Out" />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default MainNavbar;
