import React, { useState, useRef } from "react";
import "./VideoSection.css";
const VideoSection = () => {
  const videoRef = useRef();
  const [play, setPlay] = useState(false);
  const handlePlay = () => {};
  const handlePlaying = () => {
    setPlay(true);
  };
  const handlePause = () => {
    setPlay(false);
  };
  return (
    <div
      id="video"
      style={{ paddingTop: "80px" }}
      className="container pb-5 overflow-hidden"
    >
      <div
        style={{
          width: "100%",
          minWidth: "400px",
          maxWidth: "1200px",
          margin: "auto",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            overflow: "hidden",
            paddingTop: "56.25%",
            margin: "auto",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              width: "100%",
              height: "100%",
              border: "none",
            }}
            src="https://www.youtube.com/embed/S3zxsXH1gH4"
            width="560"
            height="630"
            // allowfullscreen="allowfullscreen"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
