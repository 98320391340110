import { useEffect } from "react";
import "./styles.css";
import Link from "../../../Share/Link/Link";
import Route from "../../../Share/Route/Route.js";
import twitter from "../../../../assets/icons/twitter.svg";
import linkedin from "../../../../assets/icons/linkedin.svg";

import { speakers } from "../../../../constants/speakers.js";

const SpeakerSection = ({ hideButton, limit }) => {
  const renderSpeakers = () => {
    return speakers.slice(0, `${limit}`).map((s, i) => {
      console.log(s.image);
      return (
        <div key={i} className="speaker-speaker">
          <img className="avatar" src={s.image} />
          <div className="name">{s.name}</div>
          <div className="company">{s.company}</div>
          <div className="company">{s.role}</div>
          <div className="row-align">
            {s.twitter !== "" && (
              <a target="_blank" className="content" href={s.twitter}>
                <img className="icon" src={twitter} />
              </a>
            )}
            {s.linkedin !== "" && (
              <a target="_blank" className="content" href={s.linkedin}>
                <img className="icon" src={linkedin} />
              </a>
            )}
          </div>
        </div>
      );
    });
  };
  return (
    <div className="container mt-5 py-5 ">
      <h2
        style={{ fontSize: "60px", fonWeight: 700 }}
        className="text-uppercase responsive-text text-center"
      >
        Speakers
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="speakerSection">{renderSpeakers()}</div>
        {!hideButton && (
          <Route to="/speakers">
            <Link link="" text="View All Speakers" />
          </Route>
        )}
      </div>
    </div>
  );
};

export default SpeakerSection;
