const andrewLong = require("../assets/Images/AndrewLong.png");
const aronBeierschmitt = require("../assets/Images/AronBeierschmitt.png");
const catherineCarroll = require("../assets/Images/CatherineCarroll.png");
const chrisAkhavan = require("../assets/Images/ChrisAkhavan.png");
const coreyWilton = require("../assets/Images/CoreyWilton.png");
const danBorstelmann = require("../assets/Images/DanBorstelmann.png");
const devinBecker = require("../assets/Images/DevinBecker.png");
const ericKress = require("../assets/Images/EricKress.png");
const ezgiCengiz = require("../assets/Images/EzgiCengiz.png");
const jacob = require("../assets/Images/jacob.png");
const jeromeCollin = require("../assets/Images/JeromeCollin.png");
const johnnyCasamassina = require("../assets/Images/JohnnyCasamassina.png");
const kathleenOsgood = require("../assets/Images/KathleenOsgood.png");
const kevinChou = require("../assets/Images/KevinChou.png");
const kevinZhang = require("../assets/Images/KevinZhang.png");
const kostasChalkias = require("../assets/Images/KostasChalkias.png");
const kunGao = require("../assets/Images/KunGao.png");
const leonDo = require("../assets/Images/LeonDo.png");
const marianaBernado = require("../assets/Images/MarianaBernado.png");
const mathieuNouzarth = require("../assets/Images/MathieuNouzarth.png");
const mattSorg = require("../assets/Images/MattSorg.png");
const morrisHsieh = require("../assets/Images/MorrisHsieh.png");
const neerajKashyap = require("../assets/Images/NeerajKashyap.png");
const peterKieltyka = require("../assets/Images/PeterKieltyka.png");
const ryanFoo = require("../assets/Images/RyanFoo.png");
const samParker = require("../assets/Images/SamParker.png");
const scottSunarto = require("../assets/Images/ScottSunarto.png");
const sreeramKannan = require("../assets/Images/SreeramKannan.png");
const taehoonKim = require("../assets/Images/TaehoonKim.png");
const zhuoxunYin = require("../assets/Images/ZhuoxunYin.png");

const kieferZang = require("../assets/Images/kieferZang.png");
const ariLotter = require("../assets/Images/ariLotter.png");
const deanTakahashi = require("../assets/Images/deanTakahashi.png");
const georgeIsichos = require("../assets/Images/georgeIsichos.png");
const jonAllen = require("../assets/Images/jonAllen.png");
const harlanCrystal = require("../assets/Images/harlanCrystal.png");
const shibuMathew = require("../assets/Images/shibuMathew.png");
const jenYu = require("../assets/Images/jenYu.png");
const chrisHeatherly = require("../assets/Images/chrisHeatherly.png");
const lawrenceHsieh = require("../assets/Images/larryHsieh.png");
const charnjitBansi = require("../assets/Images/charnjitBansi.png");
const evanHatch = require("../assets/Images/evanHatch.png");
const stevenChen = require("../assets/Images/stevenChen.png");
const matthewMorris = require("../assets/Images/matthewMorris.png");
const shyaanKhan = require("../assets/Images/shyaanKhan.png");
const kokulSubendran = require("../assets/Images/kokulSubendran.png");
const jenniferSanasie = require("../assets/Images/jenniferSanasie.png");
const rahatChowdhury = require("../assets/Images/rahatChowdhury.png");
const gregoryGunadi = require("../assets/Images/gregoryGunadi.png");
const xiZhang = require("../assets/Images/xiZhang.png");

export const speakers = [
  {
    name: "Kevin Chou",
    role: "Managing Partner",
    company: "SuperLayer",
    image: kevinChou,
    witter: "https://twitter.com/KevinChou",
    linkedin: " https://www.linkedin.com/in/kevinchou",
  },
  {
    name: "Chris Akhavan",
    image: chrisAkhavan,
    role: "Chief Business Officer",
    company: "Forte",
    twitter: "https://twitter.com/chrisakvn",
    linkedin: "https://www.linkedin.com/in/chrisakhavan/",
  },
  {
    name: "Kun Gao",
    image: kunGao,
    role: "Co-founder & COO",
    company: "GGWP",
    twitter: "https://twitter.com/kunoag",
    linkedin: " https://www.linkedin.com/in/kungao/",
  },
  {
    name: "Ezgi Cengiz",
    image: ezgiCengiz,
    role: "Senior Product Manager",
    company: "MetaMask",
    twitter: "https://twitter.com/ezgicengiz",
    linkedin: "https://www.linkedin.com/in/ezgi-cengiz-3a501324",
  },
  {
    name: "Aron Beierschmitt",
    image: aronBeierschmitt,
    role: "Chief Executive Officer",
    company: "Laguna Games (Crypto Unicorns)",
    twitter: "https://twitter.com/abearschmitt",
    linkedin: "https://www.linkedin.com/in/aron-beierschmitt-890b814/",
  },
  {
    name: "Catherine Carroll",
    image: catherineCarroll,
    role: "Co-CEO",
    company: "LifeForce Games",
    twitter: "https://twitter.com/drsatsuma",
    linkedin: "https://www.linkedin.com/in/satsuma/",
  },
  {
    name: "Matt Sorg",
    image: mattSorg,
    role: "Product and Tech Leadership",
    company: "Solana Labs",
    twitter: "https://twitter.com/tamgros",
    linkedin: "https://www.linkedin.com/in/matt-sorg/",
  },
  {
    name: "Mathieu Nouzareth",
    image: mathieuNouzarth,
    role: "CEO",
    company: "The Sandbox US",
    twitter: "https://twitter.com/mnouz",
    linkedin: "https://www.linkedin.com/in/mathieunouzareth/",
  },
  {
    name: "Peter Kieltyka",
    image: peterKieltyka,
    role: "Co-founder & CEO",
    company: "Horizon / Sequence",
    twitter: "https://twitter.com/peterk",
    linkedin: "https://www.linkedin.com/in/peter-kieltyka/ ",
  },
  {
    name: "Ryan Foo",
    image: ryanFoo,
    role: "Game Economist",
    company: "Delphi Digital",
    twitter: "https://twitter.com/0xRyze",
    linkedin: "https://www.linkedin.com/in/ryanmyfoo/",
  },
  {
    name: "Zhuoxun Yin",
    image: zhuoxunYin,
    role: "Co-founder & COO",
    company: "Magic Eden",
    twitter: "https://twitter.com/ZhuoxunYin",
    linkedin: "https://www.linkedin.com/in/zhuoxun-yin-3ba93728/",
  },
  {
    name: "Jacobc.eth",
    image: jacob,
    role: "Head of Product & Founder",
    company: "Game7 & HyperPlay",
    twitter: "https://twitter.com/jacobc_eth",
    linkedin: "",
  },
  {
    name: "Neeraj Kashyap",
    image: neerajKashyap,
    role: "Chief Executive Officer",
    company: "Moonstream.to",
    twitter: "https://twitter.com/zomglings",
    linkedin: "https://www.linkedin.com/in/neeraj-kashyap-784035b8/",
  },
  {
    name: "Leon Do",
    image: leonDo,
    role: "Dev",
    company: "Game7/Open Source Contributor",
    twitter: "https://twitter.com/_LeonDo_",
    linkedin: "https://www.linkedin.com/in/leon-do/",
  },
  {
    name: "Jerome Collin",
    image: jeromeCollin,
    role: "Founder & CEO",
    company: "SuperTeam Games",
    twitter: "https://twitter.com/ItsJeromeo",
    linkedin: "https://www.linkedin.com/in/jeromecollin/",
  },
  {
    name: "Sam Parker",
    image: samParker,
    role: "Solutions Engineer",
    company: "Aleo",
    twitter: "https://twitter.com/sparker2357",
    linkedin: "",
  },
  {
    name: "Kostas Chalkias",
    image: kostasChalkias,
    role: "Co-founder & Chief Cryptographer",
    company: "Mysten Labs",
    twitter: "https://twitter.com/kostascrypto",
    linkedin: "https://www.linkedin.com/in/chalkiaskostas/",
  },
  {
    name: "Mariana Bernado",
    image: marianaBernado,
    role: "Product Director",
    company: "Owl Protocol",
    twitter: "https://twitter.com/marianabernado",
    linkedin: "https://www.linkedin.com/in/mariana-bernado/",
  },
  {
    name: "Kathleen Osgood",
    image: kathleenOsgood,
    role: "Director of Business Development",
    company: "Sky Mavis (Axie Infinity)",
    twitter: "https://twitter.com/kosgoood",
    linkedin: "https://www.linkedin.com/in/kathleen-reilly-9023a730/",
  },
  {
    name: "Scott Sunarto",
    image: scottSunarto,
    role: "Founder & CEO",
    company: "Argus Labs",
    twitter: "https://twitter.com/smsunarto",
    linkedin: "https://www.linkedin.com/in/smsunarto/",
  },
  {
    name: "Sreeram Kannan",
    image: sreeramKannan,
    role: "Founder & CEO",
    company: "EigenLayer",
    twitter: "https://twitter.com/sreeramkannan",
    linkedin: "https://www.linkedin.com/in/sreeram-kannan-a397a47/",
  },
  {
    name: "Andrew Long",
    image: andrewLong,
    role: "Founder & CEO",
    company: "Raindrop Studios",
    twitter: "https://twitter.com/aslong",
    linkedin: "https://www.linkedin.com/in/andrewslong/",
  },
  {
    name: "Johnny Casamassina",
    image: johnnyCasamassina,
    role: "SVP Creative Development",
    company: "Jam City (Champions Ascension)",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/johnny-casamassina-16846810/",
  },
  {
    name: "Devin Becker",
    image: devinBecker,
    role: "Game Economist / Writer",
    company: "Nami / Naavik",
    twitter: "https://twitter.com/0xMZO",
    linkedin: "https://www.linkedin.com/in/devinbecker/",
  },
  {
    name: "Eric Kress",
    image: ericKress,
    role: "Consultant",
    company: "Deconstructor of Fun",
    twitter: "https://twitter.com/ekress",
    linkedin: "https://www.linkedin.com/in/erickress/",
  },
  {
    name: "Morris Hsieh",
    image: morrisHsieh,
    role: "Co-founder",
    company: "Crypto Towns",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/morris-hsieh/",
  },
  {
    name: "Dan Borstelmann",
    image: danBorstelmann,
    role: "Head of Product & Engineering",
    company: "Fractal",
    twitter: "https://twitter.com/dborstelmann",
    linkedin: "https://www.linkedin.com/in/dan-borstelmann-6124566a/",
  },
  {
    name: "Taehoon Kim",
    image: taehoonKim,
    role: "Co-founder & CEO",
    company: "nWay",
    twitter: "https://twitter.com/sumoru",
    linkedin: "https://www.linkedin.com/in/thtkim/",
  },
  {
    name: "Corey Wilton",
    image: coreyWilton,
    role: "Co-founder",
    company: "Mirai Labs (Pegaxy)",
    twitter: "https://twitter.com/realcoreywilton",
    linkedin: "https://www.linkedin.com/in/realcoreywilton/",
  },
  {
    name: "Kevin Zhang",
    image: kevinZhang,
    role: "Co-founder",
    company: "Curio",
    twitter: "https://twitter.com/kzdagoof",
    linkedin: "https://www.linkedin.com/in/idesignandbuildthings/",
  },
  {
    name: "Kiefer Zang",
    image: kieferZang,
    role: "Web3 Game Economy Consultant",
    company: "Economics Design",
    twitter: "https://twitter.com/KieferZang",
    linkedin: "https://www.linkedin.com/in/kieferzang/",
  },
  {
    name: "Ari Lotter",
    image: ariLotter,
    role: "Senior Software Engineer",
    company: "Horizon / Sequence",
    twitter: "https://twitter.com/shmerbgo",
    linkedin: "https://www.linkedin.com/in/arilotter/",
  },
  {
    name: "Dean Takahashi",
    image: deanTakahashi,
    role: "Journalist",
    company: "VentureBeat",
    twitter: "https://twitter.com/deantak",
    linkedin: "https://www.linkedin.com/in/deantak/",
  },
  {
    name: "George Isichos",
    image: georgeIsichos,
    role: "Game7 Contributor",
    company: "Game7",
    twitter: "https://twitter.com/gmgeek",
    linkedin: "https://www.linkedin.com/in/george-isichos/",
  },
  {
    name: "Jon Allen",
    image: jonAllen,
    role: "Game7 Contributor",
    company: "Game7",
    twitter: "https://twitter.com/jonathantallen1",
    linkedin: "https://www.linkedin.com/in/jonathan-t-allen/",
  },
  {
    name: "Harlan Crystal",
    image: harlanCrystal,
    role: "Founder & CTO",
    company: "Pocket Gems",
    twitter: "https://twitter.com/harlancrystal",
    linkedin: "https://www.linkedin.com/in/harlancrystal/",
  },
  {
    name: "Shibu Mathew",
    image: shibuMathew,
    role: "VP of Product",
    company: "ImmutableX",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/mathewshibu/",
  },
  {
    name: "Jen Yu",
    image: jenYu,
    role: "Head of Design",
    company: "Yield Guild Games",
    twitter: "https://twitter.com/yu1ogy",
    linkedin: "https://www.linkedin.com/in/itsjenyu/",
  },
  {
    name: "Chris Heatherly",
    image: chrisHeatherly,
    role: "Principal",
    company: "Great Big Beautiful Tomorrow",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/chrisheatherly/",
  },
  {
    name: "Lawrence Hsieh",
    image: lawrenceHsieh,
    role: "Naavik Pro Contributor",
    company: "Naavik",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/larryh/",
  },
  {
    name: "Charnjit Bansi",
    image: charnjitBansi,
    role: "VP of Game Design",
    company: "Polygon",
    twitter: "https://twitter.com/c_bansi",
    linkedin: "https://www.linkedin.com/in/charnjit/",
  },
  {
    name: "Evan Hatch",
    image: evanHatch,
    role: "Founder & CEO",
    company: "Worlds",
    twitter: "https://twitter.com/evanlhatch",
    linkedin: "https://www.linkedin.com/in/evanhatch/",
  },
  {
    name: "Steven Chen",
    image: stevenChen,
    role: "Game7 Contributor",
    company: "Game7",
    twitter: "https://twitter.com/stevenchen009",
    linkedin: "https://www.linkedin.com/in/stevenchen009/",
  },
  {
    name: "Matthew Morris",
    image: matthewMorris,
    role: "Evangelist",
    company: "Machinations.io",
    twitter: "https://twitter.com/MatthewSWMorris",
    linkedin: "https://www.linkedin.com/in/matthewswmorris/",
  },
  {
    name: "Shyaan Khan",
    image: shyaanKhan,
    role: "Solutions Engineer",
    company: "ImmutableX",
    twitter: "https://twitter.com/shyaankhn",
    linkedin: "https://www.linkedin.com/in/shyaan/",
  },
  {
    name: "Kokul Subendran",
    image: kokulSubendran,
    role: "Software Engineer, Eden Games",
    company: "Magic Eden",
    twitter: "https://twitter.com/0xKokul",
    linkedin: "https://www.linkedin.com/in/kokuls/",
  },
  {
    name: "Jennifer Sanasie",
    image: jenniferSanasie,
    role: "Game7 Contributor",
    company: "Game7",
    twitter: "https://twitter.com/JennSanasie",
    linkedin: "https://www.linkedin.com/in/jennifersanasie/",
  },
  {
    name: "Rahat Chowdhury",
    image: rahatChowdhury,
    role: "Lead Developer Relations Engineer",
    company: "Polygon",
    twitter: "https://twitter.com/Rahatcodes",
    linkedin: "https://www.linkedin.com/in/rahatc/",
  },
  {
    name: "Gregory Gunadi",
    image: gregoryGunadi,
    role: "Investment Team",
    company: "Makers Fund",
    twitter: "https://twitter.com/Gunaughty",
    linkedin: "https://www.linkedin.com/in/gregorygunadi/",
  },
  {
    name: "Xi Zhang",
    image: xiZhang,
    role: "Software Engineer",
    company: "Oasis Labs",
    twitter: "https://twitter.com/aefhm",
    linkedin: "https://www.linkedin.com/in/xi-zhang-9358bb1a/",
  },
];
