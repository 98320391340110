import React from "react";
import "./styles.css";
import { agenda } from "../../../../constants/agenda.js";
import { technical } from "../../../../constants/agenda.js";

const Agenda = () => {
  const renderSpeakers = () => {
    return agenda.map((a, i) => {
      return (
        <div key={i} className="section">
          <div className="agenda-row">
            <div className="agenda-type left">
              {a.length} {a.type}
            </div>
            <div className="agenda-type right">Starts: {a.startTime}</div>
          </div>
          <div className="topic left"> {a.topic}</div>
          {a.speakers !== "" && (
            <div>
              <div className="speaker"> Speakers:</div>
              <div className="speakers"> {a.speakers}</div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderTechnical = () => {
    return technical.map((a, i) => {
      return (
        <div key={i} className="section">
          <div className="agenda-row">
            <div className="agenda-type left">
              {a.length} {a.type}
            </div>
            <div className="agenda-type right">Starts: {a.startTime}</div>
          </div>
          <div className="topic left"> {a.topic}</div>
          {a.speakers !== "" && (
            <div>
              <div className="speaker"> Speakers:</div>
              <div className="speakers"> {a.speakers}</div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="container mt-5 py-5 ">
      <h2
        style={{ fontSize: "60px", fonWeight: 700 }}
        className="text-uppercase responsive-text text-center"
      >
        Main Stage
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="agendaSection">{renderSpeakers()}</div>
      </div>
      <h2
        style={{ fontSize: "60px", marginTop: "40px", fonWeight: 700 }}
        className="text-uppercase responsive-text text-center"
      >
        Technical Stage
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="agendaSection">{renderTechnical()}</div>
      </div>
    </div>
  );
};

export default Agenda;
