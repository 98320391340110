import React from "react";
import CommonButton from "../../../Share/CommonButton/CommonButton";
import "./Newsletter.css";
import Link from "../../../Share/Link/Link.js";

const Newsletter = () => {
  return (
    <div id="news">
      <div className="container d-block mt-5 mb-5 py-5">
        <h2
          style={{ fontSize: "60px", fonWeight: 700 }}
          className="text-uppercase responsive-text text-center"
        >
          JOIN OUR NEWSLETTER,
          <br />
          STAY UP-TO-DATE
        </h2>
        <div
          style={{ marginTop: "50px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Link
            link="https://g7dao.typeform.com/to/JuQfidZu"
            text="Join our Newsletter"
          />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
