import { useEffect } from "react";
import AgendaSection from "./SubCompo/Agenda/index.js";

const Agenda = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AgendaSection />
    </>
  );
};

export default Agenda;
