import { useEffect } from "react";
import About from "./SubCompo/About/About";
import HomeBanner from "./SubCompo/HomeBanner/HomeBanner";
import Newsletter from "./SubCompo/Newsletter/Newsletter";
import ThankSection from "./SubCompo/ThankSection/ThankSection";
import VideoSection from "./SubCompo/VideoSection/VideoSection";
import SpeakerSection from "./SubCompo/Speakers/index.js";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HomeBanner />
      <About />
      <VideoSection />
      <SpeakerSection limit={8} />
      <ThankSection />
      <Newsletter />
    </>
  );
};

export default Home;
