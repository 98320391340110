import React from "react";
import "./ThankSection.css";

import bga from "../../../../assets/bga.svg";
import immutable from "../../../../assets/immutable.svg";
import magic from "../../../../assets/magic-eden.svg";
import polygon from "../../../../assets//polygon.svg";
import solana from "../../../../assets//solana.svg";
import vader from "../../../../assets//vader.svg";
// Secondary Partners
import africa from "../../../../assets/partners/africa.svg";
import aleo from "../../../../assets/partners/aleo.svg";
import biconomy from "../../../../assets/partners/biconomy.svg";
import bored from "../../../../assets/partners/bored.svg";
import forte from "../../../../assets/partners/forte.svg";
import moonstream from "../../../../assets/partners/moonstream.svg";
import sequence from "../../../../assets/partners/sequence.svg";
import alliance from "../../../../assets/partners/alliance.svg";
import jamcity from "../../../../assets/partners/jamcity.svg";
import manta from "../../../../assets/partners/manta.svg";

const ThankSection = () => {
  return (
    <div className="container mt-5 py-5">
      <h2
        style={{ fontSize: "60px", fonWeight: 700 }}
        className="text-uppercase responsive-text text-center"
      >
        thank you to our
        <br />
        supporters
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src="/images/Game7.svg" className="mainLogo" />
        <div className="imageGrid">
          <img src={bga} className="logoIcons" />
          <img src={immutable} className="logoIcons" />
          <img src={magic} className="logoIcons" />
          <img src={polygon} className="logoIcons" />
          <img src={solana} className="logoIcons" />
          <img src={vader} className="logoIcons" />
          <img src={africa} className="logoIcons" />
          <img src={aleo} className="logoIcons" />
          <img src={alliance} className="logoIcons" />
          <img src={biconomy} className="logoIcons" />
          <img src={bored} className="logoIcons" />
          <img src={forte} className="logoIcons" />
          <img src={moonstream} className="logoIcons" />
          <img src={sequence} className="logoIcons" />
          <img src={jamcity} className="logoIcons" />
          <img src={manta} className="logoIcons" />
        </div>
      </div>
    </div>
  );
};

export default ThankSection;
