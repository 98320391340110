import { Link } from "react-router-dom";
import "./Route.css";

const Route = ({ text, to, children }) => {
  return (
    <Link className="text" to={to}>
      {text}
      {children}
    </Link>
  );
};

export default Route;
