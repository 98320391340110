import React from "react";

const Footer = () => {
  return (
    <div
      style={{ marginTop: "150px" }}
      className="d-flex justify-content-center align-items-center border-top border-1  py-5 red_border_color"
    >
      <div className="text-center">
        <img
          style={{ marginRight: "-22px" }}
          src="/images/brand.svg"
          className="img-fluid"
          alt="main footer logo"
        />
        <p className="font_titillium mt-4">©2022 GAM3R FORUM</p>
      </div>
    </div>
  );
};

export default Footer;
