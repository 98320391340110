import "./App.css";
// import './App.scss';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Home/SubCompo/Footer/Footer.js";

import Home from "./Components/Home/Home";
import Speakers from "./Components/Home/Speakers.js";
import Agenda from "./Components/Home/Agenda.js";

import MainNavbar from "./Components/MainNavbar/MainNavbar";
function App() {
  return (
    <div className="App">
      <Router>
        <MainNavbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/agenda" element={<Agenda />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
