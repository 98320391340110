import { useEffect } from "react";
import SpeakerSection from "./SubCompo/Speakers/index.js";

const Speakers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <SpeakerSection hideButton limit={100} />;
};

export default Speakers;
