export const agenda = [
  {
    venue: "Main Stage",
    type: "Keynote",
    length: "0:40 MIN",
    startTime: "9:00 AM",
    topic: "The Future of Games is You",
    speakers: "Jon Allen",
  },
  {
    venue: "Main Stage",
    type: "Presentation",
    length: "0:20 MIN",
    startTime: "9:40 AM",
    topic:
      "The Web3 Game Journey: Approaching the Challenges and Decisions Of Building Games in Web3 with Confidence",
    speakers: "Dan Borstelmann - Fractal",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:30 MIN",
    startTime: "10:00 AM",
    topic: "Understanding The Web3 Player",
    speakers:
      "Kevin Chou - SuperLayer, Kun Gao - GGWP,  Dean Takahashi - VentureBeat",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "10:30 AM",
    topic: "Beyond Play-to-Earn & Marketplaces",
    speakers:
      "Matt Sorg - Solana Labs, Andrew Long - Raindrop Studios, Zhuoxun Yin - Magic Eden, George Isichos - Game7",
  },
  {
    venue: "Main Stage",
    type: "Presentation",
    length: "0:15 MIN",
    startTime: "11:15 AM",
    topic: "Unveiling: HyperPlay and Better Patterns For Web3 Gaming",
    speakers: "Jacobc.eth - Game7 / HyperPlay",
  },
  {
    venue: "Main Stage",
    type: "Fireside Chat",
    length: "0:30 MIN",
    startTime: "11:30 AM",
    topic:
      "Permissionless Extensibility and Gaming: Wallet Patterns For Game-Fi",
    speakers: "Jacobc.eth - Game7 / HyperPlay, Ezgi Cengiz - MetaMask",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "12:00 PM",
    topic: "Economies Without Borders",
    speakers:
      "Harlan Crystal - Pocket Gems, Mathieu Nouzareth - Sandbox US, Shibu Mathew - ImmutableX, Gregory Gunadi - Makers Fund",
  },
  {
    venue: "Main Stage",
    type: "Lunch Break",
    length: "1:00 HR",
    startTime: "12:45 PM",
    topic: "Lunch Break",
    speakers: "",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "1:45PM",
    topic: "Don’t Launch An NFT, yet! ",
    speakers:
      "Kathleen Osgood - Sky Mavis (Axie Infinity), Chris Heatherly - Great Big Beautiful Tomorrow, Catherine Carroll - LifeForce Games, Lawrence Hsieh - Naavik",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "2:30 PM",
    topic: "From Mobile Free-to-Play, to Web3",
    speakers:
      "Jerome - SuperTeam Games, Johnny Casamassina - Jam City (Champions Ascension), Taehoon Kim - nWay, Chris Akhavan - Forte",
  },
  {
    venue: "Main Stage",
    type: "Presentation",
    length: "0:20 MIN",
    startTime: "3:15 PM",
    topic: "EigenLayer: Hyperscale Performance on Ethereum for GameFi",
    speakers: "Sreeram Kannan - EigenLayer",
  },
  {
    venue: "Main Stage",
    type: "Presentation",
    length: "0:20 MIN",
    startTime: "3:35 PM",
    topic: "Managing Chaos: Running LiveOps for Open Game Economies",
    speakers: "Aron Beierschmitt - Laguna Games (Crypto Unicorns)",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "3:55 PM",
    topic: "User Experience or Decentralization?",
    speakers:
      "Corey Wilton - Mirai Labs (Pegaxy), Charnjit Bansi - Polygon, Evan Hatch - Worlds, Steven Chen - Game7",
  },
  {
    venue: "Main Stage",
    type: "Presentation",
    length: "0:20 MIN",
    startTime: "4:40 PM",
    topic: "Solving Web3's Pain Points",
    speakers: "Ari Lotter - Horizon / Sequence",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "5:00PM",
    topic: "Designing Web3 Game Economies: A Delicate Balance",
    speakers:
      "Ryan Foo - Delphi Digital, Devin Becker - Nami / Naavik, Kiefer Zang - Economics Design, Eric Kress - Deconstructor of Fun",
  },
  {
    venue: "Main Stage",
    type: "Panel",
    length: "0:45 MIN",
    startTime: "5:45PM",
    topic: "Does Your Game Need To Be Fully On-Chain?",
    speakers:
      "Scott Sunarto - Argus Labs, Kevin Zhang - Curio, Morris Hsieh - Crypto Towns, Neeraj Kashyap - Moonstream.to",
  },
  {
    venue: "Main Stage",
    type: "End",
    length: "",
    startTime: "6:30PM",
    topic: "Happy Hour at Pauley East",
    speakers: "",
  },
];

export const technical = [
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "10:05AM",
    topic: "On-chain Privacy for Multiplayer Games",
    speakers: "Xi Zhang - Oasis Labs",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "10:45AM",
    topic: "Breaking the BOOM and BUST Cycle of Web3 Game Economies",
    speakers: "Matthew Morris - Machinations.io",
  },
  {
    venue: "Technical Stage",
    type: " Workshop",
    length: "0:40 MIN",
    startTime: "11:25 AM",
    topic: "Is Cross-Rollup The New Cross-Chain?",
    speakers: "Shyaan Khan - ImmutableX",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "12:05 PM",
    topic: "Building an on-chain game? Leave space for the Dungeon Master",
    speakers: "Moonstream DAO",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "1:00 MIN",
    startTime: "12:45 PM",
    topic: "Lunch Break",
    speakers: "",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "1:45 PM",
    topic: "Your Game will Suck Without Zero Knowledge Proofs",
    speakers: "Sam Parker - Aleo",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "2:25 PM",
    topic: "Solana for Unity Developers",
    speakers: "Kokul Subendran - Magic Eden",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "3:05:00 PM",
    topic: "Integrating dNFTs in your game: NFT Crafting",
    speakers: "Mariana Bernado - Owl Protocol",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "3:45 PM",
    topic: "Web3.unity, Randomness and UI for web3 gaming",
    speakers: "Leon Do - Game7 / Open Source Contributor",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "4:25 PM",
    topic: "Fair randomness in decentralized games",
    speakers: "Kostas Chalkias - Mysten Labs",
  },
  {
    venue: "Technical Stage",
    type: "Workshop",
    length: "0:40 MIN",
    startTime: "5:05 PM",
    topic: "Building Smart Contracts for Games on Polygon",
    speakers: "Rahat Chowdhury - Polygon",
  },
  {
    venue: "Technical Stage",
    type: "End",
    length: "",
    startTime: "6:30PM",
    topic: "Happy Hour at Pauley East",
    speakers: "",
  },
];
