import React from "react";
import "./Link.css";

const Link = ({ text, type, className }) => {
  return (
    <a className="box" disabled href={"/"} target="_blank">
      {text}
    </a>
  );
};

export default Link;
